// Generated by nuxt-prepare
export const network = {
  "logo": "",
  "title": "NextPark.pt",
  "domain": "nextpark.pt",
  "locale": "pt",
  "locales": [
    "pt"
  ],
  "country": "PT",
  "currency": "EUR",
  "timezone": "Europe/London",
  "format_date": "DD/MM/YYYY",
  "format_time": "HH:mm",
  "format_datetime": "HH:mm DD/MM/YYYY",
  "format_dayhours": 24,
  "format_firstdayofweek": "M",
  "direction": "LTR",
  "datepicker_mask": "DD/MM/YYYY",
  "datepicker_fdow": "2",
  "datepicker_clock": "24",
  "email": "contato@nextpark.pt",
  "phone": null,
  "phone_primary": null,
  "phone_secondary": null,
  "phone_openhours": null,
  "google_tag": "",
  "google_key": "",
  "google_captcha": "",
  "url_home": "https://nextpark.pt/pt/",
  "url_rule": "https://nextpark.pt/{locale}/regras"
}
export type Network = typeof network
